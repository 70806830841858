import React from 'react'
import Link from 'next/link'
import { useRouter } from 'next/router'

export interface MainNavItemProps {
    href: string
    name: string
    current?: boolean
}

const classNames = (...classes: string[]) => {
    return classes.filter(Boolean).join(' ')
}

const MainNavItem: React.FC<MainNavItemProps> = ({ href, name, current = false }) => {
    const router = useRouter()

    return (
        <Link href={href} key={name}>
            <a
                key={name}
                href={href}
                title={name}
                className={classNames(
                    router.pathname === href
                        ? 'border-mariner-500 text-mariner-500'
                        : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                    'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium hover:no-underline'
                )}
                aria-current={current ? 'page' : undefined}
            >
                {name}
            </a>
        </Link>
    )
}

export default MainNavItem
