import React from 'react'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLElement> {
    label?: string
    onClick?: (event: React.MouseEvent) => void
    size?: 's' | 'm' | 'l'
    variant: 'primary' | 'secondary' | 'tertiary' | 'neutral' | 'danger' | 'dangerlight'
}

const classes = {
    base: 'antialiased line justify-center font-medium text-center rounded-md transition-all duration-100 ease-linear items-center box-border align-middle focus:outline-none disabled:cursor-not-allowed disabled:opacity-60 ',
    size: {
        s: 'px-2.5 py-1 text-xs',
        m: 'px-4 py-2 text-sm',
        l: 'px-8 py-4 text-base',
    },
    variant: {
        primary:
            'text-white bg-crusta-500 hover:bg-crusta-700 active:bg-crusta-800 focus-visible:ring-2 focus:ring-crusta-700 focus:ring-offset-2 ',
        secondary:
            'text-gray-700 bg-gray-100 hover:bg-gray-200 active:bg-gray-300 border-2 border-crusta-500 focus-visible:ring-2 focus:ring-crusta-700 focus:ring-offset-2 visited:ring-none',
        tertiary:
            'text-gray-700 border border-gray-300 hover:bg-gray-100  active:bg-gray-200 focus-visible:ring-2 focus:ring-gray-300  focus:ring-offset-2   ',
        neutral:
            'text-gray-700 bg-transparent hover:bg-gray-100 active:bg-gray-200 focus-visible:ring-2 focus:ring-gray-700 focus:ring-offset-2',
        danger: 'text-white bg-red-600 hover:bg-red-700 active:bg-red-800 focus-visible:ring-2 focus:ring-red-800 focus:ring-offset-2',
        dangerlight:
            'text-red-500 bg-white border border-red-600 hover:text-white hover:bg-red-700 active:bg-red-800 focus-visible:ring-2 focus:ring-red-800 focus:ring-offset-2',
    },
}

const Button: React.FC<ButtonProps> = ({
    children,
    className = '',
    label,
    onClick,
    size = 'm',
    type = 'button',
    variant = 'primary',
    ...restProps
}) => {
    return (
        <button
            type={type}
            onClick={onClick}
            className={`
				${classes.base}
				${classes.variant[variant]}
				${classes.size[size]}
				${className}
			`}
            {...restProps}
        >
            {children}
            {label}
        </button>
    )
}

export default Button
