import React, { useContext } from 'react'
import { appContext } from '../../../state/AppContext'
import { isAllowed } from '../../../utils/Helpers'

export interface CanProps {
    roles: string[]
    fulfill?: 'all' | 'some'
    children: React.ReactNode
}

const Can: React.FC<CanProps> = (props) => {
    const { state } = useContext(appContext)

    const hasRoles = isAllowed(
        props.roles,
        state.userState.user?.roles ? state.userState.user?.roles : [],
        props.fulfill
    )

    if (hasRoles) return <>{props.children}</>

    return null
}

export default Can
