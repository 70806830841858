import React from 'react'

export interface AvatarProps {
    avatarSrc?: string
    className?: string
    name?: string
    notification?: boolean
    size?: 's' | 'm' | 'l' | 'xl'
}

const classes = {
    figure: 'rounded-full overflow-hidden',
    avatarSize: {
        s: 'h-6 w-6',
        m: 'h-8 w-8',
        l: 'h-12 w-12',
        xl: 'h-20 w-20',
    },
    notificationBase: 'absolute bottom-0 right-0 block rounded-full ring-2 ring-white bg-green-400',
    notificationSize: {
        s: 'h-1.5 w-1.5 ',
        m: 'h-2 w-2',
        l: 'h-2.5 w-2.5',
        xl: 'h-4 w-4',
    },
}

const placeholder = (
    <svg className="w-full h-full text-gray-300 bg-gray-100" fill="currentColor" viewBox="0 0 24 24">
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
    </svg>
)

const Avatar: React.FC<AvatarProps> = ({ avatarSrc, className = '', name, notification, size = 'm' }) => {
    return (
        <div className="relative inline-block">
            <figure
                className={`
                    ${classes.figure}
                    ${classes.avatarSize[size]}
                    ${className}
                `}
            >
                {avatarSrc ? <img src={avatarSrc} title={name} alt={name} /> : placeholder}
            </figure>
            {notification && (
                <span
                    className={`
                    ${classes.notificationBase}   
                    ${classes.notificationSize[size]}
                `}
                />
            )}
        </div>
    )
}

export default Avatar
